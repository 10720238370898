import React from "react";
import { HeaderNavContacto } from "../components/layout/HeaderNavContacto";
import { Fade } from "react-awesome-reveal";

export const Contacto = () => {
    return (
        <div>
            <HeaderNavContacto />

            <section className='contacto-area section-padding mt-5 mb-0'>
                <div className='row m-0'>
                    <div className='col-md-5 m-5 p-5'>
                        <div
                            className='single-footer-widget m-5'
                            id='info_contacto'
                        >
                            <div className='footer-heading-text'>
                                <Fade
                                    direction='left'
                                    cascade
                                    delay={1e2}
                                    triggerOnce
                                >
                                    <h3>Contáctanos</h3>
                                    <h4>Trabajemos juntos!</h4>
                                    <p>
                                        Si deseas consultar mayor información
                                        sobre nuestros servicios o tienes alguna
                                        duda al respecto, no dudes en solicitar
                                        una consultoría gratuita y uno de
                                        nuestros asesores te contactará en la
                                        brevedad.
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-5 m-0 p-0'>
                        <Fade direction='right' cascade delay={1e2} triggerOnce>
                            <div id='card_contact' className='m-5 p-5'>
                                <div
                                    className='single-footer-widget m-5'
                                    id='info_contacto'
                                >
                                    <div className='footer-heading'>
                                        <h3>Contacto</h3>
                                    </div>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Argencode SRL</strong>
                                        </span>
                                    </div>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Teléfono:</strong>{" "}
                                            <a href='tel:+5491166562774'>
                                                +54 911 6656-2774
                                            </a>
                                        </span>
                                    </div>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Email:</strong>{" "}
                                            <a href='mailto:info@argencode.com'>
                                                info@argencode.com
                                            </a>
                                        </span>
                                    </div>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Dirección:</strong> Av.
                                            Santa Fe 3312 - 14 C, Buenos Aires,
                                            Argentina
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className='grey-cr'></div>
            </section>

            <div className='copyright-area' id='contacto_page'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-12 col-md-12 text-center'>
                            <p>
                                {" "}
                                <i className='far fa-copyright'></i> 2022
                                ArgenCode SRL - Todos los derechos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
