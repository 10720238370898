import React from 'react'
import { Routes, Route, BrowserRouter, NavLink } from 'react-router-dom'
import { Inicio } from '../components/Inicio'
import { Servicios } from '../components/Servicios'
import { Contacto } from '../components/Contacto'

// import { HeaderNav } from '../components/layout/HeaderNav'
// import { Footer } from './components/layout/Footer'

export const Rutas = () => {
  return (
    <BrowserRouter>
        {/* Header y Navegación */}
        {/* <HeaderNav /> */}

        {/* Contenido Central */}
        <Routes>
            <Route path="/" element={<Inicio />} />
            <Route path="/inicio" element={<Inicio />} />
            <Route path="servicios" element={<Servicios />} />
            <Route path="contacto" element={<Contacto />} />
            
        </Routes>
        
        {/* Footer */}
        {/* <Footer /> */}

    </BrowserRouter>
  )
}
