import React from "react";
import { Footer } from "../components/layout/Footer";
import { HeaderNav } from "../components/layout/HeaderNav";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

export const Inicio = () => {
    return (
        <>
            <HeaderNav />

            <div className='home-area'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-7 col-md-12 mt-5 '>
                            <div className='main-banner-content'>
                                <Fade
                                    direction='left'
                                    cascade
                                    delay={1e3}
                                    damping={0.1}
                                    triggerOnce
                                >
                                    <h1>
                                        <span className='color-text'>
                                            Soluciones Informáticas Integrales
                                        </span>
                                    </h1>
                                </Fade>
                                <Fade
                                    delay={1e3}
                                    cascade
                                    damping={0.1}
                                    triggerOnce
                                >
                                    <h3>
                                        Fomentando la innovación en las empresas
                                        con soluciones{" "}
                                        <span className='color-text'>
                                            Tecnológicas Confiables
                                        </span>
                                    </h3>
                                </Fade>
                                <Fade
                                    delay={1e3}
                                    cascade
                                    damping={0.1}
                                    triggerOnce
                                >
                                    <p>
                                        Somos una empresa argentina joven y
                                        dinámica enfocada en brindar soluciones
                                        informáticas integrales que incluyen
                                        desarrollo de software y servicios de
                                        IT.{" "}
                                    </p>
                                </Fade>
                                <Fade
                                    direction='down'
                                    cascade
                                    delay={1e3}
                                    damping={0.1}
                                    triggerOnce
                                >
                                    <div className='banner-btn'>
                                        <Link
                                            to='/servicios'
                                            className='default-btn-one'
                                        >
                                            Leer más
                                            <span></span>
                                        </Link>
                                        {/* <a
                                            href='servicios'
                                            className='default-btn-one'
                                        >
                                            Leer más
                                            <span></span>
                                        </a> */}
                                        <Link
                                            to='/contacto'
                                            className='default-btn'
                                        >
                                            Contáctenos
                                            <span></span>
                                        </Link>
                                        {/* <a
                                            href='contacto'
                                            className='default-btn'
                                        >
                                            Contáctenos
                                            <span></span>
                                        </a> */}
                                    </div>
                                </Fade>
                            </div>
                        </div>

                        <div className='col-lg-5 mt-5'>
                            <Fade
                                direction='right'
                                cascade
                                delay={1e3}
                                damping={0.1}
                                triggerOnce
                            >
                                <div className='banner-image'>
                                    <img src='assets/img/dev6.jpg' alt='' />
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <div className='row align-items-end justify-content-start mt-5'>
                        <div className='col-lg-6 col-md-12 mt-3'>
                            <div className='main-banner-content'>
                                <Fade direction='left' delay={1e3} triggerOnce>
                                    <h3>
                                        <span className='color-text'>
                                            Nuestra Misión
                                        </span>
                                    </h3>
                                </Fade>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <p>
                                        Convertirnos en un socio estratégico de
                                        negocios aportando ideas innovadoras que
                                        permitan potenciar el resultado de
                                        nuestros clientes.
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div
                        className='row align-items-end justify-content-start mt-5'
                        id='tecnologias_aplicadas'
                    >
                        <div className='col-lg-8 col-md-12 mt-3'>
                            <Fade direction='left' delay={1e3} triggerOnce>
                                <h3>
                                    <span className='color-text'>
                                        Tecnologías Aplicadas
                                    </span>
                                </h3>
                            </Fade>
                            <Fade delay={1e3} damping={0.1} triggerOnce>
                                <p>
                                    Como lenguaje principal utilizamos PHP 8.0,
                                    como servidor de Bases de Datos MySQL.
                                    Desarrollamos con estándares del mercado
                                    como Bootstrap, JQuery, HTML5 y CSS3. Como
                                    parte de nuestros servicios, constantemente
                                    actualizamos nuestros sistemas desarrollados
                                    a la última versión estable de la tecnología
                                    disponible
                                </p>
                            </Fade>
                        </div>
                    </div>

                    <div
                        className='row align-items-end justify-content-start mt-5 mb-5'
                        id='metodologia_trabajo'
                    >
                        <div className='col-lg-7 col-md-12'>
                            <Fade direction='left' delay={1e3} triggerOnce>
                                <h3>
                                    <span className='color-text'>
                                        Metodología de Trabajo
                                    </span>
                                </h3>
                            </Fade>
                            <Fade delay={1e3} damping={0.1} triggerOnce>
                                <p>
                                    Como parte de nuestro método de trabajo,
                                    adoptamos Metodologías Agiles para el
                                    desarrollo de los proyectos de software,
                                    permitiéndonos optimizar los tiempos de
                                    entrega y generar mayor sinergia y
                                    colaboración con nuestros clientes.
                                </p>
                                <p>
                                    Esto nos facilita la posibilidad de reportar
                                    a nuestros clientes con precisión el estado
                                    de avance de cada uno de los requerimientos
                                    y módulos desarrollados otorgándole una
                                    mayor transparencia a todo el proceso.
                                </p>
                            </Fade>
                        </div>
                    </div>

                    <div
                        className='row align-items-end justify-content-start mt-5 mb-5'
                        id='metodologia_trabajo'
                    >
                        <div className='col-lg-7 col-md-12'>
                            <Fade direction='left' delay={1e3} triggerOnce>
                                <h3>
                                    <span className='color-text'>
                                        Equipo de Trabajo
                                    </span>
                                </h3>
                            </Fade>
                            <Fade delay={1e3} damping={0.1} triggerOnce>
                                <p>
                                    Nuestro equipo de trabajo está conformado
                                    por desarrolladores con experiencia en
                                    proyectos para pequeñas y medianas empresas,
                                    en la que deben trabajar con requerimientos
                                    no documentados y necesitan de una mayor
                                    interacción con el cliente, centrándose en
                                    brindar soluciones informáticas solidas y
                                    agiles
                                </p>
                            </Fade>
                        </div>
                    </div>
                </div>

                <div className='grey-cr'></div>
            </div>

            <Footer />
        </>
    );
};
