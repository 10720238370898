import React from "react";
import { Fade } from "react-awesome-reveal";

export const Footer = () => {
    return (
        <>
            <section className='footer-area section-padding'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='single-footer-widget'>
                                <div className='footer-heading'>
                                    <Fade
                                        direction='left'
                                        delay={1e3}
                                        damping={0.1}
                                        triggerOnce
                                    >
                                        <h3>CONTACTO</h3>
                                    </Fade>
                                </div>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Argencode SRL</strong>
                                        </span>
                                    </div>
                                </Fade>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Teléfono:</strong>{" "}
                                            <a href='tel:+5491166562774'>
                                                +54 911 6656-2774
                                            </a>
                                        </span>
                                    </div>
                                </Fade>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Email:</strong>{" "}
                                            <a href='mailto:info@argencode.com'>
                                                info@argencode.com
                                            </a>
                                        </span>
                                    </div>
                                </Fade>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <div className='footer-info-contact'>
                                        <span>
                                            <strong>Dirección:</strong> Av.
                                            Santa Fe 3312 - 14 C, Buenos Aires,
                                            Argentina
                                        </span>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='copyright-area'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-12 col-md-12 text-center'>
                            <p>
                                {" "}
                                <i className='far fa-copyright'></i> 2022
                                ArgenCode SRL - Todos los derechos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
