import './App.css';

import { Rutas } from './router/Rutas';

function App() {
  return (
    <>
     <Rutas></Rutas>
    </>
  );
}

export default App;
