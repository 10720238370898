import React from "react";
import { Footer } from "../components/layout/Footer";
import { HeaderNav } from "../components/layout/HeaderNav";
import { Fade } from "react-awesome-reveal";

export const Servicios = () => {
    return (
        <>
            <HeaderNav />

            <div className='home-area'>
                <div className='container'>
                    <div className='row' id='titulo_servicios'>
                        <div className='main-banner-content col-12 col-md-6'>
                            <Fade direction='left' triggerOnce>
                                <h3>
                                    <span className='color-text'>
                                        Nuestros Servicios
                                    </span>
                                </h3>
                            </Fade>
                        </div>
                    </div>

                    <div className='row align-items-center p-0'>
                        <div className='col-lg-7 col-md-12 p-0'>
                            <div className='main-banner-content p-0'>
                                <Fade
                                    direction='left'
                                    cascade
                                    delay={1e2}
                                    triggerOnce
                                >
                                    <h2>
                                        <span className='color-text2'>
                                            Desarrollo de soluciones a medida
                                        </span>
                                    </h2>
                                </Fade>
                                <Fade
                                    delay={1e2}
                                    cascade
                                    damping={0}
                                    triggerOnce
                                >
                                    <p>
                                        Desarrollamos software a medida desde su
                                        concepción hasta su implementación y
                                        mantenimiento.
                                    </p>
                                    <p>
                                        Trabajamos con un proceso de desarrollo
                                        basado en tecnologías ágiles logrando
                                        así una rápida respuesta a los
                                        requerimientos y cercanía permanente del
                                        cliente al producto en su evolución, lo
                                        que permite una validación constante de
                                        su funcionalidad y calidad.
                                    </p>
                                </Fade>
                            </div>
                        </div>

                        <div className='col-lg-5 p-0'>
                            <Fade
                                direction='right'
                                delay={1e3}
                                damping={0.2}
                                triggerOnce
                            >
                                <div className='banner-image p-0'>
                                    <img src='assets/img/dev1.jpg' alt='' />
                                </div>
                            </Fade>
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-lg-5'>
                            <Fade
                                direction='left'
                                cascade
                                delay={1e3}
                                damping={0}
                                triggerOnce
                            >
                                <div className='banner-image'>
                                    <img src='assets/img/dev3.jpg' alt='' />
                                </div>
                            </Fade>
                        </div>

                        <div className='col-lg-7 col-md-12justify-content-end'>
                            <div className='main-banner-content justify-content-end'>
                                <Fade
                                    direction='right'
                                    cascade
                                    delay={1e3}
                                    damping={0}
                                    triggerOnce
                                >
                                    <h2>
                                        <span className='color-text2'>
                                            Reingeniería de productos
                                        </span>
                                    </h2>
                                </Fade>

                                <Fade delay={1e3} damping={0} triggerOnce>
                                    <p>
                                        Mejoramos la funcionalidad de su
                                        infraestructura de información actual
                                        desarrollando complementos, software
                                        intermedio, extendiendo funcionalidad
                                        del software existente o hasta
                                        desarrollando productos totalmente
                                        nuevos basados en los existentes
                                        incluyendo procesos de ingeniería
                                        inversa de ser necesario.
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>

                    <div className='row align-items-center mb-5'>
                        <div className='col-lg-7 col-md-12'>
                            <div className='main-banner-content'>
                                <Fade direction='left' delay={1e3} triggerOnce>
                                    <h2>
                                        <span className='color-text2'>
                                            Diseño de infraestructura
                                        </span>
                                    </h2>
                                </Fade>
                                <Fade delay={1e3} damping={0.1} triggerOnce>
                                    <p>
                                        Nuestra propuesta incluye una
                                        consultoria sobre el diseño de la
                                        infraestructura requerida para el
                                        correcto funcionamiento del nuevo
                                        sistema de gestión. Realizamos un
                                        relevamiento de la infraestructura
                                        actual y recomendamos e implementamos la
                                        mejor alternativa para alojar el nuevo
                                        desarrollo. El entregable sera un
                                        documento con el detalle de las
                                        recomendaciones del entorno (on-premise
                                        o cloud), recursos necesarios, esquemas
                                        de resguardo de información y
                                        redundancia necesaria para operar 7x24.
                                    </p>
                                    <p>
                                        Una vez finalizado el nuevo desarrollo
                                        brindamos servicios de soporte a
                                        usuario, mantenimiento de
                                        infraestructura y resguardo de
                                        información.
                                    </p>
                                </Fade>
                            </div>
                        </div>

                        <div className='col-lg-5 mt-5'>
                            <Fade
                                direction='right'
                                delay={1e3}
                                damping={0.1}
                                triggerOnce
                            >
                                <div className='banner-image'>
                                    <img src='assets/img/dev4.jpg' alt='' />
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};
