import React from 'react'
import { NavLink , Link } from 'react-router-dom'

export const HeaderNav = () => {
  return (
	<>
    <div className="navbar-area">

        {/* --------- Menu responsive (Para móviles)---------- */}
	    <div className="techmax-responsive-nav index-navber-responsive">

			<div className="container">
				<div className="row" id="row_menu">

				<div className="col">
				    <div className="techmax-responsive-menu">
					    <div className="logo">
						    <Link to="/inicio">
						
							    <img src="./assets/img/logoAC.svg" className="white-logo" alt="logo" id="logo_responsive" />
							    <img src="./assets/img/logoAC.svg" className="black-logo" alt="logo" />
						    </Link>
					    </div>
				    </div>
				</div>

				<div className="col">
				    <nav className="navbar navbar-default navbar-fixed-top d-flex align-items-end">
			            <div className="container-fluid d-flex justify-content-end mb-0 pb-0 mt-2" id="contenedor_boton">
				            <div className="navbar-header">
					            <button id="boton_menu" type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
        				            <span className="pe-7s-menu"></span>
      				            </button>
				            </div>
				            <div className="collapse navbar-collapse mt-4" id="bs-example-navbar-collapse-1">
				            <ul className="nav navbar-nav text-center">
					            <li className="active mt-1 li_menu_responsive">
									<NavLink to="/inicio" className="nav-link">Inicio</NavLink>
								</li>
					            <li className="mt-1 li_menu_responsive">
									<NavLink to="/servicios" className="nav-link">Servicios</NavLink>
								</li>
					            <li className="mt-1 li_menu_responsive">
									<NavLink to="/contacto" className="nav-link">Contacto</NavLink>
								</li>
				            </ul>
      			            </div>
			            </div>
		            </nav>
				</div>

				</div>
			</div>

		</div>

		{/* --------- Menu responsive (Para pantallas grandes)---------- */}
		<div className="techmax-nav index-navber">
			<div className="container">
				<nav className="navbar navbar-expand-md navbar-light row">
					<NavLink className="navbar-brand col-7" to="/inicio">
						<img src="assets/img/logoAC.svg" className="white-logo" alt="logo" />
						<img src="assets/img/logoAC.svg" className="black-logo" alt="logo" />
					</NavLink>
					<div className="collapse navbar-collapse mean-menu col-5" id="navbarSupportedContent">
						<ul className="navbar-nav">
							<li className="nav-item"> 
								<NavLink to="/inicio" className="nav-link">Inicio</NavLink>
							</li>
							<li className="nav-item">
							    <NavLink to="/servicios" className="nav-link">Servicios</NavLink>
							</li>			
							<li className="nav-item">
							    <NavLink to="/contacto" className="nav-link">Contacto</NavLink>
							</li>
						</ul>		
					</div>
				</nav>
			</div>
		</div>

	</div>

	    {/* --------- Menu responsive ---------- */}

	</>
  )
}
